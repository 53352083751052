//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head () {
    return {
      title:
        'Manchester Pest Control | Pest Control & Wasp Nest Treatments | Manchester, Stockport & Cheshire',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Pest Control and Wasp Nest Treatment in Manchester, Stockport and Cheshire.'
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: 'Manchester Pest Control | Pest Control & Wasp Nest Treatments | Manchester, Stockport & Cheshire'
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: 'Pest Control and Wasp Nest Treatment in Manchester, Stockport and Cheshire.'
        }
      ]
    }
  }
}
