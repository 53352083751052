import { render, staticRenderFns } from "./SideBar.vue?vue&type=template&id=a966db90&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavServices: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/NavServices.vue').default,NavLocations: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/NavLocations.vue').default,NavIdentify: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/NavIdentify.vue').default,NavRoads: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/NavRoads.vue').default})
