import { render, staticRenderFns } from "./index.vue?vue&type=template&id=23d9a3d2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hero: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/Hero.vue').default,BannerDefault: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/BannerDefault.vue').default,BannerVideo: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/BannerVideo.vue').default,NavIcons: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/NavIcons.vue').default,SideBar: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/SideBar.vue').default})
